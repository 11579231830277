import React, { useState } from "react";
import { Link } from "react-router-dom";
import Section from "./Section";
import SkillsBadge from "./SkillsBadge";
import {
  REACT,
  JAVASCRIPT,
  CSS3,
  BOOTSTRAP,
  HTML5,
  JAVA,
  GIT,
  AGILESCRUM,
  EXPRESSJS,
  NODEJS,
  GATSPYJS,
  CSHARP,
  ASPDOTNET,
} from "../technologies";

const About = () => {
  const [long, setLong] = useState(true);

  return (
    <div className="d-flex flex-column">
      <button
        className="btn btn-outline-primary mx-auto my-3"
        onClick={() => setLong(!long)}
      >
        {long ? "TL;DR" : "I Got Time"}
      </button>

      {long && (
        <div className="text-white">
          <Section heading="Who's This Guy?">
            <p>Glad you asked!</p>
            <p>
              I'm currently a Software Developer II at{" "}
              <a href="https://jenzabar.com/" style={{ color: "#f8e875" }}>
                {" "}
                Jenzabar Inc
              </a>
              . At work I do a lot of C# and .NET, and in my personal time I
              like to work with JavaScript (React, Express, and Node to be more
              specific).
            </p>
            <p>
              I'm located in Harrisonburg, VA living with my girlfriend and our
              two cats. When I'm not spending time with them I'm normally
              playing Nintendo Switch or spending an embarassing amount of time
              on YouTube.
            </p>
            <p>
              If you'd like to get in contact with me feel free to do so. My
              contact information can be found{" "}
              <Link to="/contact" style={{ color: "#f8e875" }}>
                here
              </Link>{" "}
            </p>
          </Section>
          <Section heading="Technologies">
            <ul className="pl-0">
              <SkillsBadge bgColor={REACT.bgColor} color={REACT.color}>
                React
              </SkillsBadge>
              <SkillsBadge
                bgColor={JAVASCRIPT.bgColor}
                color={JAVASCRIPT.color}
              >
                JavaScript
              </SkillsBadge>
              <SkillsBadge bgColor={CSS3.bgColor} color={CSS3.color}>
                CSS 3
              </SkillsBadge>
              <SkillsBadge bgColor={BOOTSTRAP.bgColor} color={BOOTSTRAP.color}>
                Bootstrap
              </SkillsBadge>
              <SkillsBadge bgColor={HTML5.bgColor} color={HTML5.color}>
                HTML 5
              </SkillsBadge>
              <SkillsBadge bgColor={JAVA.bgColor} color={JAVA.color}>
                Java
              </SkillsBadge>
              <SkillsBadge bgColor={CSHARP.bgColor} color={CSHARP.color}>
                C#
              </SkillsBadge>
              <SkillsBadge bgColor={ASPDOTNET.bgColor} color={ASPDOTNET.color}>
                .NET
              </SkillsBadge>
            </ul>
            <p>
              React and JavaScript are the technologies I've worked with most in
              my personal time. I'm learning new things about the language and
              framework all the time.
            </p>
            <p>
              I was introduced to C# and .NET when joining Jenzabar and am
              having a blast learning the language.
            </p>
          </Section>
          <Section heading="Other Skills">
            <ul className="pl-0">
              <SkillsBadge bgColor={GIT.bgColor} color={GIT.color}>
                Git
              </SkillsBadge>
              <SkillsBadge
                bgColor={AGILESCRUM.bgColor}
                color={AGILESCRUM.color}
              >
                Agile / Scrum
              </SkillsBadge>
            </ul>
            <p>
              The majority of my version control experience is with Git from
              using GitHub. I've gathered a little experience using both
              Mercurial and Subversion.
            </p>
            <p>
              I additonally have experience developing software in an
              Agile/SCRUM environment.
            </p>
          </Section>
          <Section heading="Current Focus">
            <ul className="pl-0">
              <SkillsBadge bgColor={CSHARP.bgColor} color={CSHARP.color}>
                C#
              </SkillsBadge>
              <SkillsBadge bgColor={ASPDOTNET.bgColor} color={ASPDOTNET.color}>
                .NET
              </SkillsBadge>
              <SkillsBadge bgColor={EXPRESSJS.bgColor} color={EXPRESSJS.color}>
                ExpressJS
              </SkillsBadge>
              <SkillsBadge bgColor={NODEJS.bgColor} color={NODEJS.color}>
                NodeJS
              </SkillsBadge>
            </ul>
            <p>
              I'm currently getting my hands dirty with back-end technologies.
              Node and Express seemed like the logical next step after learning
              React and JavaScript. I'm also learning C# and .NET at work.
            </p>
          </Section>
          <Section heading="On My Radar">
            <ul className="pl-0">
              <SkillsBadge bgColor={GATSPYJS.bgColor} color={GATSPYJS.color}>
                GatspyJS
              </SkillsBadge>
            </ul>
          </Section>
        </div>
      )}

      {!long && (
        <div className="text-white">
          <Section heading="TL;DR">
            <ol>
              <li>Software Developer II at Jenzabar Inc.</li>
              <li>Currently learning C#, .NET, NodeJS and ExpressJS</li>
              <li>
                Tech I'm Familiar With:
                <ul>
                  <li>React</li>
                  <li>JavaScript</li>
                  <li>CSS 3</li>
                  <li>Bootstrap 4</li>
                  <li>HTML 5</li>
                  <li>Java</li>
                  <li>C#</li>
                  <li>.NET</li>
                  <li>Git</li>
                </ul>
              </li>
              <li>
                Check out my projects{" "}
                <Link to="/projects" style={{ color: "#f8e875" }}>
                  here
                </Link>{" "}
              </li>
              <li>
                Check out my resume{" "}
                <Link to="/resume" style={{ color: "#f8e875" }}>
                  here
                </Link>{" "}
              </li>
              <li>
                Find my contact info{" "}
                <Link to="/contact" style={{ color: "#f8e875" }}>
                  here
                </Link>{" "}
              </li>
            </ol>
          </Section>
        </div>
      )}
      <Link to="/projects" className="btn btn-outline-primary mx-auto my-3">
        Check out my projects
      </Link>
    </div>
  );
};

export default About;
