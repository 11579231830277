import React from "react";
import PropTypes from "prop-types";

const Section = ({ heading, children }) => {
  return (
    <section>
      <h1
        className="pb-2"
        style={{
          fontSize: "2rem",
          borderBottom: "1px solid #f8e875"
        }}
      >
        {heading}
      </h1>
      {children}
    </section>
  );
};

Section.propTypes = {
  heading: PropTypes.string.isRequired
};

export default Section;
