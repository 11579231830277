import React from "react";

const PageFooter = () => {
  return (
    <footer className="text-white text-center p-3">
      <p>Bryce Morris &copy; {new Date().getFullYear()}</p>
    </footer>
  );
};

export default PageFooter;
