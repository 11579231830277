import React from "react";
import { Link } from "react-router-dom";

const ProjectBanner = ({
  color,
  background,
  font,
  link,
  titleClass,
  children,
  ...props
}) => {
  return (
    <li
      {...props}
      className={`${titleClass} project-animate my-3 text-center`}
      style={{
        background: background || "inherit",
        borderRadius: "5px"
      }}
    >
      <Link
        to={link}
        className="project-link"
        style={{
          color: "inherit"
        }}
      >
        <h2 className="mb-0 p-3">{children}</h2>
      </Link>
    </li>
  );
};

export default ProjectBanner;
