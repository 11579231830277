import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

const PageHeader = () => {
  const activeStyle = {
    textDecoration: "#f8e875 underline"
  };

  return (
    <header className="page-header text-white">
      <Navbar bg="transparent" variant="dark" expand="md">
        <div className="container">
          <Navbar.Brand>
            <Link to="/">
              <span className="name">Bryce Morris</span>
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <NavLink
                to="/about"
                className="mx-3 my-1 my-md-0 text-center"
                activeStyle={activeStyle}
              >
                About
              </NavLink>
              <NavLink
                to="/projects"
                className="mx-3 my-1 my-md-0 text-center"
                activeStyle={activeStyle}
              >
                Projects
              </NavLink>
              <NavLink
                to="/resume"
                className="mx-3 my-1 my-md-0 text-center"
                activeStyle={activeStyle}
              >
                R&eacute;sum&eacute;
              </NavLink>
              <NavLink
                to="contact"
                className="mx-3 my-1 my-md-0 text-center"
                activeStyle={activeStyle}
              >
                Contact
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
};

export default PageHeader;
