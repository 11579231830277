import React from "react";

const SkillsBadge = ({ bgColor, color, children }) => {
  return (
    <li
      className="badge m-2 p-2"
      style={{
        backgroundColor: bgColor,
        color: color || "black",
        fontSize: "1rem",
        fontWeight: "500"
      }}
    >
      {children}
    </li>
  );
};

export default SkillsBadge;
