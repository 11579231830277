import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";
import ProjectBanner from "./ProjectBanner";
import ProjectPage from "./ProjectPage";
import {
  REACT,
  JAVASCRIPT,
  CSS3,
  BOOTSTRAP,
  HTML5,
  JAVA,
  EXPRESSJS,
  NODEJS,
  MONGODB,
  FIREBASE,
} from "../technologies";

import bernBackground from "../images/bern-background.png";
import houdini from "../images/houdini.png";
import daze from "../images/daze.png";
import golfSucks from "../images/golf-sucks.png";
import dexter from "../images/dexter.png";
import bernsteinBlaster from "../images/bernstein-blaster.png";

const Projects = () => {
  return (
    <Switch>
      <Route exact path="/projects">
        <ul className="text-white pl-0" style={{ listStyle: "none" }}>
          <ProjectBanner
            background="#121212"
            titleClass="houdini-title"
            link="/projects/houdini"
          >
            Houdini
          </ProjectBanner>
          <ProjectBanner
            background="linear-gradient(45deg, #081018, #213950)"
            titleClass="daze-title"
            link="/projects/daze"
          >
            Daze
          </ProjectBanner>
          <ProjectBanner
            background="#4eabe6"
            titleClass="golf-sucks-title"
            link="/projects/golf-sucks"
          >
            GOLF SUCKS
          </ProjectBanner>
          <ProjectBanner
            background="#343a40"
            titleClass="dexter-title"
            link="/projects/dexter"
          >
            <span>Dexter</span>
            <span>V2</span>
          </ProjectBanner>
          <ProjectBanner
            background={`url(${bernBackground})`}
            titleClass="bernstein-blaster-title"
            link="/projects/bernstein-blaster"
          >
            Bernstein Blaster
          </ProjectBanner>
        </ul>
      </Route>
      <Route path="/projects/houdini">
        <ProjectPage
          title="Houdini"
          titleClass="houdini-title"
          tech={[REACT, BOOTSTRAP, CSS3, EXPRESSJS, MONGODB, NODEJS]}
          inProgress={true}
          image={houdini}
        >
          A restaurant queue application. When complete, authenticated users
          will be able to place orders for customers and have them added to a
          MongoDB database. Those orders can then be received by kitchen staff
          and displayed within five seconds of placing an order. Additionally,
          Admins or Managers can edit certain fields of Employees such as first
          and last name and can promote users up to their own status.
        </ProjectPage>
      </Route>
      <Route path="/projects/daze">
        <ProjectPage
          title="Daze"
          titleClass="daze-title"
          tech={[REACT, BOOTSTRAP, CSS3, FIREBASE]}
          onHold={true}
          image={daze}
          deployLink="https://determined-payne-056fe7.netlify.com/"
          githubLink="https://github.com/morrisbc/daze"
        >
          A mental health diary application. When complete users will be able
          document their sleep, exercise, and diet to provide them with an
          overall idea of what's contributing to their mental state. Users will
          be able to view a graph of their history over the past week as well as
          see progress towards daily goals.
        </ProjectPage>
      </Route>
      <Route path="/projects/golf-sucks">
        <ProjectPage
          title="GOLF SUCKS"
          titleClass="golf-sucks-title"
          tech={[JAVASCRIPT, CSS3, HTML5, FIREBASE]}
          inProgress={false}
          image={golfSucks}
          deployLink="https://morrisbc.github.io/GolfSucks/"
          githubLink="https://github.com/morrisbc/GolfSucks"
        >
          A golf statistics tracking application. Users can log their full
          eighteen hole scorecards as well as their practice sessions. Users'
          logged scorecards are used to provide trophies for lowest nine hole
          score, lowest eighteen hole score, scorecards logged, and practice
          sessions logged.
        </ProjectPage>
      </Route>
      <Route path="/projects/dexter">
        <ProjectPage
          title={
            <Fragment>
              <span>Dexter</span>
              <span>V2</span>
            </Fragment>
          }
          titleClass="dexter-title"
          tech={[JAVASCRIPT, BOOTSTRAP, CSS3, HTML5]}
          inProgress={false}
          image={dexter}
          deployLink="https://morrisbc.github.io/dexter/"
          githubLink="https://github.com/morrisbc/dexter"
        >
          A Pok&eacute;mon pok&eacute;dex application. Users can search for any
          pre-generation VIII Pok&eacute;mon and receive: pok&eacute;dex number,
          a front and rear image, type, height, and weight. Additionally the
          application will provide a list of all incoming damage multipliers by
          type indicating which move types will do more damage to said
          Pok&eacute;mon. Finally, the application can also use the previously
          mentioned functionality to evaluate a six Pok&eacute;mon team and give
          users insight on potential flaws in their team composition.
        </ProjectPage>
      </Route>
      <Route path="/projects/bernstein-blaster">
        <ProjectPage
          title="Bernstein Blaster"
          titleClass="bernstein-blaster-title"
          tech={[JAVA]}
          inProgress={false}
          image={bernsteinBlaster}
          githubLink="https://github.com/morrisbc/BernsteinBlaster"
        >
          An arcade style game. The goal of the game is to survive as long as
          possible while destroying the fleet of F-Ships sent by Lord Bernstein.
          This was a project for my CS 349: Developing Multimedia class.
        </ProjectPage>
      </Route>
    </Switch>
  );
};

export default Projects;
