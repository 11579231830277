export const REACT = { name: "React", bgColor: "#61dafb", color: "black" };
export const JAVASCRIPT = {
  name: "JavaScript",
  bgColor: "#f0db4f",
  color: "black"
};
export const CSS3 = { name: "CSS 3", bgColor: "#264de4", color: "white" };
export const BOOTSTRAP = {
  name: "Bootstrap",
  bgColor: "#563d7c",
  color: "white"
};
export const HTML5 = { name: "HTML 5", bgColor: "#e44d26", color: "white" };
export const JAVA = { name: "Java", bgColor: "#ed1d25", color: "white" };
export const GIT = { name: "Git", bgColor: "#f1502f", color: "white" };
export const AGILESCRUM = {
  name: "Agile / Scrum",
  bgColor: "#6c757d",
  color: "white"
};
export const EXPRESSJS = { name: "ExpressJS", bgColor: "#444", color: "white" };
export const NODEJS = { name: "NodeJS", bgColor: "#3c873a", color: "white" };
export const GATSPYJS = {
  name: "GatspyJS",
  bgColor: "#362066",
  color: "white"
};
export const CSHARP = { name: "C#", bgColor: "#9400d3", color: "white" };
export const ASPDOTNET = {
  name: "ASP.NET",
  bgColor: "#1e90ff",
  color: "white"
};
export const MONGODB = {
  name: "MongoDB",
  bgColor: "#3c873a",
  color: "white"
};
export const FIREBASE = {
  name: "Firebase",
  bgColor: "#ffa000",
  color: "white"
};
