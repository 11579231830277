import React from "react";
import { Link } from "react-router-dom";
import Section from "./Section";
import resume from "../files/bryce-morris-resume.pdf";

const Resume = () => {
  return (
    <div className="d-flex flex-column text-white">
      <a
        className="d-flex justify-content-center align-items-center btn btn-outline-primary text-white mx-auto my-3"
        href={resume}
        download
      >
        Download <i className="material-icons ml-2">cloud_download</i>
      </a>
      <Section heading="Technical Skills">
        <ul>
          <li>React</li>
          <li>JavaScript</li>
          <li>CSS 3</li>
          <li>Bootstrap 4</li>
          <li>HTML 5</li>
          <li>Java</li>
          <li>Git</li>
          <li>Agile / Scrum</li>
        </ul>
      </Section>
      <Section heading="Projects">
        <p>
          I just so happen to have a whole section on these{" "}
          <Link to="/projects" style={{ color: "#f8e875" }}>
            here
          </Link>
        </p>
      </Section>
      <Section heading="Education">
        <h2 style={{ fontSize: "1.35rem" }}>
          <span style={{ fontStyle: "italic" }}>James Madison University</span>{" "}
          - Harrisonburg, VA
        </h2>
        <ul>
          <li>B.S. Computer Science - Dec. 2018</li>
          <li>GPA: 3.432</li>
          <li>Dean's List - Fall 2015, Spring 2016, 2017</li>
        </ul>
      </Section>

      <Section heading="Work Experience">
        <h2 style={{ fontSize: "1.35rem" }}>
          <span style={{ fontStyle: "italic" }}>
            Geek Squad Advanced Repair Agent:
          </span>{" "}
          Aug. 2019 - Present
        </h2>
        <ul>
          <li>
            Perform PC repairs including OS/software installations, hardware
            replacements, and general tune ups
          </li>
          <li>
            Perform Apple iPhone repair including displays, batteries, and
            cameras
          </li>
        </ul>
        <h2 style={{ fontSize: "1.35rem" }}>
          <span style={{ fontStyle: "italic" }}>
            Geek Squad Consultation Agent:
          </span>{" "}
          Jan. 2018 - Aug. 2019
        </h2>
        <ul>
          <li>
            Educate and support clients regarding issues with their various
            devices
          </li>
          <li>
            Communicate closely with the team of other agents to ensure problem
            resolution and achieve client satisfaction
          </li>
        </ul>
      </Section>
      <Section heading="Activities">
        <h2 style={{ fontSize: "1.35rem" }}>
          <span style={{ fontStyle: "italic" }}>Upsilon Pi Epsilon:</span> Apr.
          2017 – Dec. 2018, Computer Science Honor Society
        </h2>
        <ul>
          <li>Historian, Dec. 2017 – May 2018</li>
        </ul>
      </Section>

      <Link to="/contact" className="btn btn-outline-primary mx-auto my-3">
        Drop me a line
      </Link>
    </div>
  );
};

export default Resume;
