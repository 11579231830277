import React from "react";
import SkillsBadge from "./SkillsBadge";

const ProjectPage = ({
  title,
  titleClass,
  tech,
  inProgress,
  onHold,
  image,
  deployLink,
  githubLink,
  children,
}) => {
  return (
    <div className="text-white">
      <h1 className={`${titleClass} text-center`}>{title}</h1>
      <ul className="d-flex flex-wrap justify-content-center pl-0">
        {tech.map((technology, idx) => (
          <SkillsBadge
            key={idx}
            bgColor={technology.bgColor}
            color={technology.color}
          >
            {technology.name}
          </SkillsBadge>
        ))}
        {inProgress && (
          <SkillsBadge bgColor="#ff6700" color="white">
            In Progress
          </SkillsBadge>
        )}
        {onHold && (
          <SkillsBadge bgColor="#ff0000" color="white">
            On Hold
          </SkillsBadge>
        )}
      </ul>
      <img src={image} alt={`${title}`} className="w-100 mb-3" />
      <p>{children}</p>
      {(deployLink || githubLink) && (
        <div className="d-flex justify-content-center">
          {deployLink && (
            <a href={deployLink} className="text-white mr-3">
              <i className="material-icons" style={{ fontSize: "2.2rem" }}>
                web
              </i>
            </a>
          )}
          {githubLink && (
            <a href={githubLink} className="text-white ml-3">
              <i className="material-icons" style={{ fontSize: "2.2rem" }}>
                code
              </i>
            </a>
          )}
        </div>
      )}
    </div>
  );
};

export default ProjectPage;
