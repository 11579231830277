import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="home">
      <h1 className="mb-0">Hey</h1>
      <hr style={{ border: "1px solid #f8ef75" }} />
      <h1 className="mt-0">How's it going?</h1>
      <div className="last-fade d-flex flex-column">
        <h2>
          Dont answer out loud. <br /> You'll look odd.
        </h2>
        <a href="https://www.github.com/morrisbc">
          <i className="fab fa-github"></i>
        </a>
        <Link to="/about" className="btn btn-outline-primary mt-3">
          Who's this guy?
        </Link>
      </div>
    </div>
  );
};

export default Home;
