import React from "react";
import Section from "./Section";

const Contact = () => {
  return (
    <div className="text-white">
      <Section heading="Contacts">
        <ul className="pl-0" style={{ listStyle: "none" }}>
          <li className="d-flex align-items-center my-3">
            <i className="material-icons-outlined mr-3">email</i>{" "}
            BryceM961@gmail.com
          </li>
          <li className="d-flex align-items-center my-3">
            <i className="material-icons-outlined mr-3">smartphone</i> (540)
            421-1192
          </li>
        </ul>
      </Section>
      <Section heading="Social">
        <a href="http://www.github.com/morrisbc" className="text-white">
          <i className="fab fa-github" style={{ fontSize: "2rem" }}></i>
        </a>
      </Section>
      <p className="text-center my-3">Hope to hear from you soon!</p>
    </div>
  );
};

export default Contact;
